<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-simple-table
          class="tbl elevation-2"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="item in headers"
                  :key="item.text"
                  :class="`text-${item.align}`"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <TbodyPreRender
              :is-loading="isLoading"
              :cols="headers.length"
              :items-length="items.length"
            />
            <tbody v-if="!isLoading">
              <tr
                v-for="item in items"
                :key="`patient-item-in-${item.idx}`"
              >
                <td class="text-center">
                  <router-link :to="`/patients/${item.extra_info.idx}`">
                    {{ item.basic_info.name }}
                  </router-link>
                </td>
                <td class="py-2">
                  <v-chip
                    small
                    outlined
                    class="mr-2"
                    :color="(item.basic_info.gender === 'male') ? 'blue' : 'red'"
                  >
                    {{ item.basic_info.getGender() }}, {{ item.basic_info.age }}세, {{ item.extra_info.weight }}Kg
                  </v-chip>
                  <v-chip
                    outlined
                    small
                  >
                    <Promised
                      :promise="item.extra_info.getAddress()"
                    >
                      <template v-slot:combined="{ data }">
                        {{ data }}
                      </template>
                    </Promised>
                  </v-chip>
                  <br>
                  <v-chip
                    label
                    small
                    class="mt-2"
                  >
                    {{ item.extra_info.medical_information }}
                  </v-chip>
                </td>
                <td class="py-2">
                  <v-chip
                    label
                    small
                    class="mr-2"
                  >
                    {{ item.extra_info.more_information }}
                  </v-chip>
                  <v-chip
                    label
                    small
                    class="mr-2"
                  >
                    {{ item.extra_info.getWantCarerGender() }}
                  </v-chip>
                  <div class="mt-2">
                    <v-chip
                      label
                      small
                      class="mr-2"
                    >
                      {{ item.extra_info.started_at }} ~ {{ item.extra_info.ended_at }}
                    </v-chip>
                    <v-chip
                      label
                      small
                      class="mr-2"
                    >
                      {{ item.extra_info.care_time_info }}, {{ item.extra_info.daily_payment }}
                    </v-chip>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <v-pagination
          v-model="model.pagination.page"
          class="pagination-circle"
          circle
          :length="model.pagination.length"
          :total-visible="model.pagination.totalVisible"
          prev-icon="keyboard_arrow_left"
          next-icon="keyboard_arrow_right"
          @input="getItems(model.pagination.page)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import CarerApplyList from './model/carerApplyList'

export default {
  components: {
    TbodyPreRender
  },
  props: ['value', 'requestType'],
  data() {
    return {
      isLoading: false,
      isShowAddSection: false,
      model: new CarerApplyList(),
      items: [],
      headers: [
        { text: '환자명', align: 'center' },
        { text: '환자정보', align: 'center' },
        { text: '요청정보', align: 'center' }
      ],
      formData: {
        start_date: '',
        end_date: ''
      }
    }
  },
  created() {
    this.model.carerIdx = this.value.idx
    this.model.requestType = this.requestType
    this.getItems(1)
  },
  methods: {
    async getItems(page) {
      this.isLoading = true

      this.model.pagination.page = page
      const { items, pagination } = await this.model.getList()
      if (items) {
        this.items = items
        this.model.pagination = { ...pagination } // vue 2 not catch deep object
      }

      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
