/* eslint-disable max-classes-per-file */
import { Api, makeGetUrl } from '@/libraries/api'
import { PatientSchema } from '../../patients/model/patientSchema'
import paginationLib from '../../_elements/pagination/paginationLib'

const CarerApplyList = class {
  #carerIdx = null

  #requestType = ''

  constructor() {
    this.query = ''
    // this.pagination = new Pagination()
    this.pagination = {}
  }

  set carerIdx(idx) {
    this.#carerIdx = idx
  }

  set requestType(requestType) {
    this.#requestType = requestType
  }

  get carerIdx() {
    return this.#carerIdx
  }

  async getList() {
    const url = `/admin/work/${this.#requestType}/caregiver/${this.#carerIdx}`
    const params = {
      page: this.pagination.page,
      limit: this.pagination.limit,
      query: this.query
    }
    const apiResult = await Api().get(makeGetUrl(url, params))
    const { items, pagination } = apiResult.data.data
    return {
      items: items.map((item) => new PatientSchema(item)),
      pagination: paginationLib.paginationMapper(pagination)
    }
  }
}

export default CarerApplyList
